import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import collapse from "@alpinejs/collapse";

import.meta.glob(["../fonts/**"]);

// Init Alpine
window.Alpine = Alpine;

Alpine.plugin(intersect);
Alpine.plugin(collapse);

Alpine.store("menu", {
    isOpen: false,

    open() {
        this.isOpen = true;
    },

    close() {
        this.isOpen = false;
    },

    toggle() {
        this.isOpen = !this.isOpen;
    },
});

Alpine.start();
